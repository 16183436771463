<!-- 热招显示的数据样式 -->
<template>
  <div class>
    <div
      class="main_content"
      v-for="(item, index) in jobHotLists"
      :key="index"
      @click="goHotDetail(item)"
    >
      <template>
        <div class="show">
          <p>{{ item.jd_title }}</p>
          <p>
            <span class="smk-xsx">{{ item.place_name }}</span>
            <!-- <span class="smk-xsx">{{item.jd_people_min}}人</span> -->
            <span class="smk-xsx" v-if="isOddjob == 0">{{
              item.jd_exp_name
            }}</span>
            <span class="smk-xsx" v-if="isOddjob == 0">{{
              item.jd_edu_name
            }}</span>
            <span class="smk-xsx" v-if="isOddjob == 1">{{
              item.work_hour
            }}</span>
            <span v-if="isOddjob == 0"
              >{{ item.jd_salary_min }}{{ -item.jd_salary_max }}元</span
            >
            <span v-else
              >{{ item.jd_salary_max }}元/{{ item.jd_salary_type }}</span
            >
          </p>
        </div>
        <div class="operation" v-if="item.jd_tags_name != ''">
          <div
            class="boon"
            v-for="(item1, index1) in item.jd_tags_name"
            :key="index1"
          >
            {{ item1 }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  company_scale,
  work_time,
  educationBackground,
} from "@/utils/searchtype";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  props: {
    jobHotList: {
      type: Array,
    },
  },
  data() {
    //这里存放数据
    return {
      educationBackground: [],
      company_scale: [],
      work_time: [],
      jobHotLists:this.jobHotList,

      isOddjob: 0, //是否为零工 0是全职 1是零工
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    //前往热招职位详情
    goHotDetail(item) {
      console.log(item);
      this.$router.push({
        path: "/jobdetails",
        query: {
          id: item.id,
        },
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.isOddjob = sessionStorage.getItem("odd_job");
    if (this.isOddjob == 1) {
      this.jobHotLists = this.jobHotLists.filter(item=>{
        return item.odd_status == 2;
      })
    }
    this.educationBackground = educationBackground;
    this.company_scale = company_scale;
    this.work_time = work_time;
    console.log(3);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    console.log(4);
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="scss" scoped>
/* //@import url(); 引入公共css类 */
.main_content {
  cursor: pointer;
  width: 100%;
  height: 118px;
  background-color: #fffcfc;
  /* margin-top: 14px; */
  /* margin-bottom: 20px; */
  border-bottom: 1px solid #f1f1f1;
  position: relative;
}
/*  */
.show {
  float: left;
  margin-left: 30px;
}
.show p {
  font-size: 18px;
  color: #414a60;
}
.show p span {
  color: #8d92a1;
  font-size: 13px;
}
.operation {
  float: right;
  line-height: 118px;
  margin-right: 20px;
  overflow: hidden;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.boon {
  width: auto;
  display: inline-block;
  padding: 0 1em;
  height: 26px;
  line-height: 26px;
  border-radius: 10px;
  margin-right: 5px;
  border: 1px solid #dcdfe6;
  color: #909399;
  font-size: 14px;
}
.liulan {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #909399;
  font-size: 14px;
}
.smk-xsx::after {
  content: "|";
  color: #e4e7ed;
  padding: 0 7px 0 7px;
}
</style>
